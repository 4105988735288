/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery-ui@1.12.1/ui/i18n/datepicker-sl.min.js
 * - /npm/jquery-ui@1.12.1/ui/i18n/datepicker-it.min.js
 * - /npm/jquery-ui@1.12.1/ui/i18n/datepicker-it-CH.min.js
 * - /npm/jquery-ui@1.12.1/ui/i18n/datepicker-de.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
